<template>
  <span>
    <span
      v-if="margedTypes.has(item.type)"
      :class="'mr-'+margedTypes.get(item.type)"
    />{{text}}
  </span>
</template>

<script>
export default {
  computed: {
    margedTypes () {
      return new Map()
        .set('labels', 1)
        .set('users', 10)
    },
    text () {
      return this.item.fullname ?? this.item.name ?? this.item.number ?? ''
    }
  },
  props: {
    item: Object
  }
}
</script>
